.values-item {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.values-item h3 {
  color: #0f3a57e6;
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: Roboto Flex;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.values-item p {
  color: #0f3a5766;
  font-family: Roboto Flex;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.values-row-1, .values-row-2 {
  justify-content: center;
  display: flex;
}

.values-content {
  flex-direction: column;
  gap: 80px;
  margin-top: 40px;
  display: flex;
}

@media only screen and (max-width: 480px) {
  .values-row-1, .values-row-2 {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }

  .values-item {
    max-width: 316px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .values-row-1, .values-row-2 {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }

  .values-item {
    width: 316px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .values-row-1, .values-row-2 {
    flex-direction: column;
    align-items: center;
    gap: 100px;
  }

  .values-item {
    width: 316px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .values-row-1, .values-row-2 {
    flex-direction: row;
    gap: 25px;
  }

  .values-item {
    width: 316px;
  }
}

@media only screen and (min-width: 1201px) {
  .values-row-1, .values-row-2 {
    flex-direction: row;
    gap: 100px;
  }

  .values-item {
    width: 316px;
  }
}

@media only screen and (min-width: 1301px) {
  .values-row-1, .values-row-2 {
    flex-direction: row;
    gap: 148px;
  }

  .values-item {
    width: 316px;
  }
}

/*# sourceMappingURL=index.2dbcb6c8.css.map */
