.values-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.values-item h3 {
    font-family: 'Roboto Flex';
    font-weight: 700;
    color: #0F3A57E6;
    font-size: 24px;
    line-height: 36px;
    margin-top: 40px;
    margin-bottom: 20px;
}

.values-item p {
    font-family: 'Roboto Flex';
    font-weight: 700;
    color: #0F3A5766;
    font-size: 16px;
    line-height: 24px;
}

.values-row-1, .values-row-2 {
    display: flex;
    justify-content: center;
}

.values-content {
    display: flex;
    flex-direction: column;
    gap: 80px;
    margin-top: 40px;
}

/* Mobile */
@media only screen and (max-width: 480px) {
    .values-row-1, .values-row-2 {
        flex-direction: column;
        align-items: center;
        gap:50px;
    }

    .values-item {
        max-width: 316px;
        padding-left: 24px;
        padding-right: 24px;
    }
}

/* Tablets */
@media only screen and (min-width: 481px) and (max-width:768px) {
    .values-row-1, .values-row-2 {
        flex-direction: column;
        align-items: center;
        gap:50px;
    }
    
    .values-item {
        width: 316px;
    }
}

/* Small screens */
@media only screen and (min-width: 769px) and (max-width:1024px) {
    .values-row-1, .values-row-2 {
        flex-direction: column;
        align-items: center;
        gap:100px;
    }
    .values-item {
        width: 316px;
    }
}

/* Large screens */
@media only screen and (min-width: 1025px) and (max-width:1200px) {
    .values-row-1, .values-row-2 {
        flex-direction: row;
        gap:25px;
    }
    .values-item {
        width: 316px;
    }
}

/* Extra large screens */
@media only screen and (min-width: 1201px) {
    .values-row-1, .values-row-2 {
        flex-direction: row;
        gap:100px;
    }
    .values-item {
        width: 316px;
    }
}

@media only screen and (min-width: 1301px) {
    .values-row-1, .values-row-2 {
        flex-direction: row;
        gap:148px;
    }
    .values-item {
        width: 316px;
    }
}